<template>
  <div>
    <div class="mainform">
      <div class="mainHeader">
        <span v-if="!form.number">起草合同</span>
        <span v-if="form.number">合同{{form.number}}</span>
        <span @click="back" class="back">返回></span>
      </div>
      <div class="form">
        <el-form class="content bascform" ref="form" :class="{disabled:formDisabled}" :disabled="formDisabled"
          :rules="rules" :model="form" label-width="140px">
          <div class="col col4" v-if="form.number">
            <el-form-item label="合同编号">
              <span class="onlyText">
                {{form.number}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同类型" prop="contractType">
              <el-select v-model="form.contractType" placeholder="请选择">
                <el-option v-for="(item,index) in contractTypeOption" :key="index" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="业务类型" prop="businessType">
              <el-select v-model="form.businessType" placeholder="请选择">
                <el-option v-for="item in businessTypeOption" :key="item.label" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="我方签约主体" prop="mySubject">
              <div class="flex-table">
                <el-form-item>
                  <el-select @change="ScopeChange" v-model="form.mySubject" style="width: 30%;">
                    <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc"
                      :value="itme.businessScope"></el-option>
                  </el-select>
                  <el-select @change="companyChange" v-model="form.mySubjectCode" style="width: 70%;">
                      <el-option :key="index" v-for="(itme,index) in companyCodeList" :label="`${itme.companyCode}-${itme.companyName}`"
                        :value="`${itme.companyCode}-${itme.companyName}`"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="合同生效期" required>
              <div style="display:flex">
                <el-form-item >
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.strStartDate" @change="timeForm"
                    style="width: 45%;"></el-date-picker>
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.strEndDate" @change="timeFormEnd"
                    style="width: 55%;"></el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="签约负责人" prop="jpersonincharge">
              <el-input v-model="form.jpersonincharge"></el-input>
            </el-form-item>
          </div>
          <div class="col col4" v-if="form.differentiatedContract!=1">
            <el-form-item label="供应商签约主体" prop="subject" >
              <el-select size="small" filterable remote :remote-method="requestSupplierOption" @change="SupplierChange"
                :loading="supplierLoading" v-model="form.subject" placeholder="编码/名称">
                <el-option v-for="(item) in supplierOptions" :key="item.key" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4" v-if="form.differentiatedContract==1">
            <el-form-item label="供应商签约主体">
              <span class="onlyText">
                {{form.subjectCode+'-'+form.subject}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" v-if="isShowOrderinfo">
            <el-form-item label="关联订单" prop="associatedOrder">
              <el-input v-model="form.associatedOrder">
                <template #append>
                  <el-button type="text" @click="chooseOrder">选择订单</el-button>
                </template>
              </el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同介质" prop="medium" >
              <el-radio-group v-model="form.medium" @change="choiceChange">
                <el-radio :label="0">纸质合同</el-radio>
                <el-radio :label="1">电子合同</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="col col4" v-if="isSignature" style="vertical-align: top;">
            <el-form-item label="签章方式" prop="signature">
              <el-radio-group v-model="form.signature">
                <el-radio :label="0">签约平台签章</el-radio>
                <el-radio :label="1">用印平台签章</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="col col4" v-show="showsupplierIsSignature" style="vertical-align: top;">
            <el-form-item label="供应商是否签章" prop="supplierIsSignature">
              <el-radio-group v-model="form.supplierIsSignature">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="col col4" v-if="isShowFileinput">
            <el-form-item label="合同文件">
              <el-button type="text" v-model="form.file" @click="uploadFileBtn">{{fieldList.name}}</el-button>
              <el-button type="text" @click="delHtFile" icon="el-icon-close"></el-button>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;" v-if="form.status!=null">
            <el-form-item label="状态">
              <span class="onlyText">
                {{form.status==0?'待审核':form.status==1?'驳回':form.status==2?'待签章':form.status==3?'待他签'
                :form.status==4?'已签章' : form.status==5?'草稿' :form.status==6?'作废':form.status==7?'待确认'
                :form.status==8?'待归档': form.status}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" v-if="!isShowFileinput">
            <el-form-item label="合同文件" v-model="form.file" class="contractfile" prop="file">
              <el-button v-if="Templatebtn" class="Templatebtn" size="small" type="primary" label="1" plain @click="UsetheTemplate">使用模板
              </el-button>
              <el-upload :action="actionURL" :on-preview="handlePreview" :on-remove="handleRemove"
                :before-remove="beforeRemove" :headers="tokenHeader" :on-success="handleSuccess" multiple
                :file-list="form.file">
                <el-button v-if="!formDisabled" class="uploadFiles" size="small" type="primary" label="2" plain>上传合同文件</el-button>
              </el-upload>
            </el-form-item>
          </div>
          <div class="col col4" v-if="!isShowFileinput">
            <el-form-item label="合同附件" v-model="file2" class="contractfile">
              <el-upload :action="actionURL" :on-preview="handlePreview" :on-remove="handleRemove"
                :before-remove="beforeRemove" :headers="tokenHeader" :on-success="handleSuccess2" multiple
                :file-list="file2">
                <el-button v-if="!formDisabled" class="uploadFiles" size="small" type="primary" label="2" plain>上传合同附件</el-button>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <approval-mind></approval-mind>
    <dialog-box ref="addDialog" :orderData="orderData" dialogWidth="826px" :dialogShow="dialogShow"
      @handleClose='handleClose' @handleAffirm="handleAffirm" title="关联订单"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'handleAffirm', type: 'primary' }]"
      componentName="orderdialogBody"></dialog-box>
    <dialog-box ref="templeteDialog" :formData="form" dialogWidth="1100px" :dialogShow="draftaContractdialogShow"
      @handleClose='handleClose' @confirmDrafta='confirmDrafta' title="起草合同"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'confirmDrafta', type: 'primary' }]"
      componentName="DraftaContractdialog"></dialog-box>
      </div>
        <div class="action">
          <el-button v-if="(form.status==7||form.status==1)&&canAbandoned" type="primary" size="medium" @click="Abandoned">作废</el-button>
          <el-button v-if="form.status==null||form.status==5" type="primary" size="medium" @click="saveBtn">保存</el-button>
          <el-button v-if="form.status==0&&canAction" type="primary" size="medium" @click="reject">驳回</el-button>
          <!-- <el-button v-if="form.status==null||form.status==5
          ||(form.status==0&&canAction)||(form.status==1&&canAction)
          ||(form.status==7)" type="primary" size="medium" @click="submitBtn">提交</el-button> -->
          <el-button v-if="form.status==null||form.status==5
          ||(form.status==0&&canAction)||(form.status==1&&canAction)
          ||(form.status==7&&canAbandoned)" type="primary" size="medium" @click="submitBtn">提交</el-button>
          <!-- <el-button v-if="form.status==null||form.status==5
          ||(form.status==0)||(form.status==1)
          ||(form.status==7)" type="primary" size="medium" @click="submitBtn">提交</el-button> -->
        </div>
    </div>
  </div>
</template>

<script>
import DialogBox from '@/components/plug/DialogBox.vue'
import ApprovalMind from '@/components/ApprovalMind'
import { baseHost } from '@/assets/js/PublicData.js'
// import { fileHandle } from '@/assets/js/FileAction.js'
import { request, requestForm, deepClone, baseURL } from '@/assets/js/http.js'
import { formData, rules, businessTypeOption, contractTypeOption } from './js/config.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
import dayjs from 'dayjs'
export default {
  name: 'DraftaContract',
  components: { DialogBox, ApprovalMind },
  data: function () {
    FlowCtlData.docid = ''
    return {
      form: deepClone(formData),
      orderData: [],
      rules: rules,
      businessScopeList: [],
      contractTypeOption: contractTypeOption,
      companyCodeList: [],
      businessTypeOption: businessTypeOption,
      dialogShow: false,
      formDisabled: false,
      canAbandoned: false, // 可以作废
      canAction: false, // 可以操作
      isSignature: false, // 签章方式是否显示
      draftaContractdialogShow: false, // 起草合同弹窗
      showsupplierIsSignature: true, // 供应商是否签章是否显示
      isShowFileinput: false, // 上传文件input,
      isShowOrderinfo: false,
      supplierOptions: [],
      supplierLoading: false,
      Templatebtn: true,
      fieldList: {},
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      concatId: '',
      file2: []
    }
  },
  created () {
    const id = this.$route.query.id
    if (id) {
      this.isSignature = true
    }
    this.contractDetails()
  },
  watch: {
    $route: {
      handler () {
        this.contractDetails()
      }
    },
    form: {
      handler: function (data) {
        if (data.contractType === '2') {
          this.isShowOrderinfo = false
        } else {
          this.isShowOrderinfo = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    contractDetails () {
      request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
        this.businessScopeList = response
      })
      const id = this.$route.query.id
      FlowCtlData.flowid = 'ContractAward'
      FlowCtlData.docid = id
      FlowCtlData.initialize().then(() => {
        if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
          this.canAction = true
        } else {
          this.canAction = false
        }
      })
      FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.PurchasingManager = val })
      FlowCtlData.getApprPsn('560667585021624320').then((val) => { this.form.legal = val })
      if (id) {
        request('/api/pact/contract/queryOne?id=' + id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            this.form.strStartDate = this.form.startDate
            this.form.strEndDate = this.form.endDate
            if (this.form.status === null || this.form.status === 1 ||
            this.form.status === 5 || this.form.status === 9 || this.form.status === 7) {
              this.formDisabled = false
            } else {
              this.formDisabled = true
            }
            // 如果有id,状态为0待审核 4 已归档 2 待签章
            if (this.form.status === 0 || this.form.status === 4 || this.form.status === 2) {
              this.Templatebtn = false
            }
            this.requestSupplierOption(this.form.subject)
            if (this.form.differentiatedContract === 0) {
              if (this.form.createUserId === localStorage.getItem('userName')) {
                this.canAbandoned = true
              } else {
                this.canAbandoned = false
              }
            } else {
              request(`/api/supplier/basicData/getByErpCode/${this.form.subjectCode}`, 'get').then((response) => {
                if (response.code === '200') {
                  console.log(response)
                  if (response.data === null || response.data === 'null') {
                    this.$message({
                      message: '未找到采购员！',
                      type: 'error'
                    })
                    this.canAbandoned = false
                    return false
                  } else {
                    if (response.data.buyerCode === localStorage.getItem('userName')) {
                      this.canAbandoned = true
                    } else {
                      this.canAbandoned = false
                    }
                  }
                }
              })
            }
            // console.log(this.form)
            for (let i = 0; i < this.form.file.length; i++) {
              if (this.form.file[i].type !== '1') {
                this.file2.push(this.form.file[i])
                this.form.file.splice(i, 1)
                i--
              }
            }
          }
          this.ScopeChange()
          FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.PurchasingManager = val })
          FlowCtlData.getApprPsn('560667585021624320').then((val) => { this.form.legal = val })
        })
      }
    },
    requestSupplierOption (val) { // 供应商
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({ key: `${item.erpCode}`, label: `${item.erpCode}-${item.name}`, value: `${item.name}` })
          }
        }
        this.supplierLoading = false
      })
    },
    // 选择电子合同显示签章方式
    choiceChange (val) {
      if (val === 1) {
        this.isSignature = true
      } else {
        this.isSignature = false
      }
    },
    delHtFile () {
      this.fieldList = {}
      this.form.file = []
      this.isShowFileinput = false
    },
    // 选择合同生效日期转换格式
    timeForm () {
      if (this.form.strStartDate) {
        this.form.strStartDate = dayjs(this.form.strStartDate).format('YYYY-MM-DD')
      } else {
        this.form.strStartDate = ''
      }
    },
    timeFormEnd () {
      if (this.form.strEndDate > new Date(this.form.strStartDate)) {
        if (this.form.strEndDate) {
          this.form.strEndDate = dayjs(this.form.strEndDate).format('YYYY-MM-DD')
        }
      } else {
        this.$message({
          message: '结束日期不能小于开始日期',
          type: 'error'
        })
        this.form.strEndDate = ''
      }
    },
    saveBtn () { // 暂存  状态为草稿
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      const obj = {
        ...this.form,
        status: 5
      }
      obj.file = this.form.file.concat(this.file2)
      let url = ''
      if (this.form.status !== null || this.form.status === 5 || this.form.status === 9) {
        url = '/api/pact/contract/approvalUpdate'
      } else {
        url = '/api/pact/contract/saveOperate'
      }
      request(url, 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/ContractOrder/ContractList')
        }
      })
    },
    submitBtn: function () { // 提交
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      // 如果是纸质合同  需要默认签章方式为用印平台签章
      if (this.form.medium === 0 && this.form.signature === '') {
        this.form.signature = 1
      }
      const obj = {
        ...this.form,
        status: 0,
        concatId: this.concatId
      }
      obj.file = this.form.file.concat(this.file2)
      let url = ''
      if (this.form.status || this.form.status === 0) {
        obj.concatId = this.form.concatId
        url = '/api/pact/contract/approvalUpdate'
      } else {
        url = '/api/pact/contract/saveOperate'
      }
      obj.businessScope = obj.mySubject
      FlowCtlData.getNodeAction('TJ', obj).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this).then(() => {
            if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
              obj.status = 2
            }
            FlowCtlData.setFormData(FlowCtlData, obj)
            request(url, 'POST', obj).then((Response) => {
              if (Response.code === '200') {
                if (!this.$route.query.id) {
                  FlowCtlData.docid = Response.data.id
                }
                FlowCtlData.setNodeAction().then((val) => {
                  if (val) {
                    FlowCtlData.formAlert(this, 'success')
                    this.$router.push('/ContractOrder/ContractList')
                  } else {
                    FlowCtlData.formAlert(this, 'error')
                  }
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    reject () {
      const obj = {
        ...this.form,
        status: 1
      }
      FlowCtlData.getNodeAction('BH', obj).then((val) => {
        if (val) {
          FlowCtlData.alertBHDialog(this).then(({ value }) => {
            FlowCtlData.setFormData(FlowCtlData, obj)
            request('/api/pact/contract/approvalUpdate', 'POST', obj).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction(value).then((val) => {
                  if (val) {
                    FlowCtlData.formAlert(this, 'success')
                    this.$router.push('/ContractOrder/ContractList')
                  } else {
                    FlowCtlData.formAlert(this, 'error')
                  }
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    Abandoned () {
      const obj = {
        ...this.form,
        status: 6
      }
      this.$confirm('是否作废？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/pact/contract/approvalUpdate', 'POST', obj).then((Response) => {
          if (Response.code === '200') {
            FlowCtlData.formAlert(this, 'success')
            this.$router.push('/ContractOrder/ContractList')
          }
        })
      }).catch(() => {
        FlowCtlData.formAlert(this, 'info')
      })
    },
    chooseOrder () { // 选择订单按钮
      const obj = {
        providerCode: this.form.subjectCode, // 供应商
        companyCode: this.form.mySubjectCode.split('-')[0]
      }
      if (!this.form.mySubjectCode) {
        FlowCtlData.formAlert(this, 'error', '请先选择我方及供应商主体！')
        return false
      }
      console.log('companyCode', obj.companyCode)
      request('/api/order/order/getOrderListByUserId', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.orderData = res.data
        }
      })
      this.dialogShow = true
    },
    ScopeChange: function () {
      this.form.companyCode = ''
      if (this.form.mySubject !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.mySubject, 'get').then((response) => {
          this.companyCodeList = response
        })
      }
    },
    SupplierChange () {
      for (const item of this.supplierOptions) {
        if (this.form.subject === item.value) {
          this.form.subjectCode = item.key
        }
      }
    },
    companyChange: function (data) {
      for (var item of this.companyCodeList) {
        if (item.companyCode === data) {
          this.form.companyName = item.companyName
        }
      }
    },
    UsetheTemplate (val) { // 使用模板
      this.draftaContractdialogShow = true
    },
    handleClose () {
      this.dialogShow = false // 关闭按钮
      this.draftaContractdialogShow = false
    },
    handleAffirm () {
      var newListData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      if (newListData.length > 0) {
        var newList = []
        newList = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
        newList.forEach(item => {
          this.form.associatedOrder = this.form.associatedOrder + item.orderCode + ','
        })
        this.form.associatedOrder = this.form.associatedOrder.substring(0, this.form.associatedOrder.length - 1)
      } else {
        this.$message({
          message: '请选择要关联的订单',
          type: 'warning'
        })
      }
      this.dialogShow = false // 确认按钮
    },
    handlePreview (file) {
      // fileHandle.handlePreview(file)
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.file.length; i++) {
        if (this.form.file[i].id === file.id) {
          this.form.file.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        console.log(response.data)
        const ccc = response.data
        if (ccc.length > 1) {
          ccc.splice(0, 1)
        }
        ccc[0].type = 1
        const bbb = this.form.file
        bbb.asdasd = 122
        if (this.form.file[0]) {
          this.form.file = ccc
          this.$confirm('合同文件只能上传一个，是否替换？').then(req => {
            if (req === 'confirm') {
              this.form.file = ccc
            } else {
              this.form.file = bbb
            }
          }).catch(
            err => {
              console.log(this.form.file)
              this.form.file = bbb
              console.log(err)
            }
          )
        } else {
          this.form.file = ccc
        }
        console.log(this.form.file)
      }
    },
    handleSuccess2 (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(i => {
          i.type = 2
          this.file2.push(i)
        })
        console.log(this.file2)
        // const ccc = response.data
        // this.file2 = ccc
      }
    },
    uploadFileBtn () {
      this.handlePreview(this.fieldList)
    },
    confirmDrafta () { // 使用模板创建合同
      this.isShowFileinput = true
      var newListData = this.$refs.templeteDialog.$refs.content.contractList
      var list = []
      for (const key in newListData) {
        list.push(
          { keyword: key, value: newListData[key] })
      }
      list.forEach(item => {
        if (item.keyword === 'qianyueshijian') {
          console.log('item', item.keyword)
          item.value = dayjs(item.value).format('YYYY-MM-DD')
        }
      })
      var obj = {
        templateId: this.$refs.templeteDialog.$refs.content.templateId,
        concatName: this.form.name,
        mySubject: this.form.mySubject,
        signature: this.form.signature,
        mySubjectCode: this.form.mySubjectCode,
        alreadySubject: this.form.subject.split('-')[0],
        list
      }
      request('/api/pact/contract/electronicContract', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.fieldList = response.data
          this.concatId = response.data.concatId
          this.form.file.push({ ...this.fieldList })
          this.fieldList.name = response.data.name + '.pdf'
          this.fieldList.id = response.data.id
        }
        this.draftaContractdialogShow = false
      })
    },
    // 返回
    back: () => {
      window.history.back()
    }

  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';

  .mainform {
    .contractfile {
      position: relative;
    }

    .Templatebtn {
      position: absolute;
      top: 3px;
      left: 120px;
    }
    :deep(.el-col-11) {
      max-width: 48.09000%;
    }
    .disabled{
      :deep(.el-form-item__content){
        .el-upload--text{
          display: none;
        }
        .el-upload-list__item:first-child {
          margin-top: 5px;
        }
      }
    }
    .col{
      vertical-align: top
    }
    // .col4{
    //   padding: 0px 15px 0px 0px;
    //   display: inline-block;
    // }
  }
</style>
