// import dayjs from 'dayjs'
export var formData = {
  name: '', // 合同名称
  number: '', // 合同编号
  contractType: '', // 合同类型
  subject: '', // 供应商主体
  status: null,
  businessType: '', // 业务类型
  medium: 0, // 合同介质
  signature: '', // 签章方式
  mySubject: '', // 我方主体
  associatedOrder: '', // 关联订单
  supplierIsSignature: null, // 供应商是否签章：0是、1否
  myIsSignature: null, //  我方是否签章：0是、1否
  rejectMessage: '', // 驳回原因
  file: [], // 合同文件
  contractEffective: '', // 合同有效期
  contractFileId: '',
  startDate: '', // 合同开始时间
  endDate: '',
  differentiatedContract: null,
  strStartDate: null,
  strEndDate: null,
  ypersonincharge: '', // 乙方
  jpersonincharge: '' // 甲方负责人
}

export var contractTypeOption = [
  { label: '订单', value: '1' },
  { label: '框架合同', value: '2' },
  { label: '订购合同', value: '3' },
  { label: '非标合同', value: '4' }
]

export var businessTypeOption = [
  { label: '生产物料类', value: '1' },
  { label: '资产类', value: '2' },
  { label: '服务类', value: '3' },
  { label: '基建类', value: '4' },
  { label: '委托采购', value: '5' },
  { label: '其它协议', value: '6' }
]

export var rules = {
  name: [
    { required: true, message: '输入合同名称', trigger: 'blur' }
  ],
  contractType: [
    { required: true, message: '请选择合同类型', trigger: 'blur' }
  ],
  mySubject: [
    { required: true, message: '请选择我方签约主体', trigger: 'blur' }
  ],
  mySubjectCode: [
    { required: true, message: '请选择我方签约主体', trigger: 'blur' }
  ],
  businessType: [
    { required: true, message: '请选择业务类型', trigger: 'blur' }
  ],
  jpersonincharge: [
    { required: true, message: '请输入签约负责人', trigger: 'blur' }
  ],
  subject: [
    { required: true, message: '请输入供应商签约主体', trigger: 'blur' }
  ],
  medium: [
    { required: true, message: '请选择合同介质', trigger: 'change' }
  ],
  signature: [
    { required: true, message: '请选择签章方式', trigger: 'change' }
  ],
  associatedOrder: [
    { required: true, message: '请选择关联订单', trigger: 'blur' }
  ],
  strStartDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'change' }
  ],
  strEndDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'change' }
  ],
  contractEffective: [
    { required: true, message: '请输入合同有效期,例如:1年', trigger: 'blur' }
  ],
  file: [
    { required: true, message: '请上传合同文件', trigger: 'blur' }
  ],
  supplierIsSignature: [
    { required: true, message: '请选择供应商是否签章', trigger: 'change' }
  ]
}
